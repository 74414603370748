import './functions/share';

//Libs
import SplitType from 'split-type';
import Swiper from 'swiper';
import Navigation from 'swiper/modules/navigation.mjs';
import Autoplay from 'swiper/modules/autoplay.mjs';
import Parallax from 'swiper/modules/parallax.mjs';
import Pagination from 'swiper/modules/pagination.mjs';
import Keyboard from 'swiper/modules/keyboard.mjs';
import gsap from 'gsap';
import isMobile from 'ismobilejs';

//Functions
import toggleFn from './functions/toggle';
import accordionFn from './functions/accordion';
import isInViewport from './functions/in_viewport';
import customSelect from './functions/select-custom';
import scrollTo from './functions/scrollto';
import createShareButton from './functions/mobile_share';

window.is_mobile = isMobile().phone || window.innerWidth < 1024;

window.addEventListener('DOMContentLoaded', () => {
  if (!window.is_mobile) {
    customSelect();
    menuHandle();
    recruitmentProccesAnimation();
  }
  else {
    menuHandleMobile();
  }

  toggleFn();
  initSlider();
  buttonApplyHandle();
  searchFormHandle();
  portraitsHandle();
  eventsAnimation();
  newsletterForm();
  accessibility();
  createShareButton();
  document.cookie = 'visited=true';

  setTimeout(() => {
    accordionFn();

    new SplitType('[data-split-type]', {
      types: 'lines',
    });

    homeAnimation();
  }, 200);
});

let lastScrollTop = 0;
window.addEventListener('scroll', (e) => {
  const st = window.pageYOffset || document.documentElement.scrollTop;

  if (st > lastScrollTop) {
    // downscroll code
    window.scrollDirection = 'down';
  }
  else {
    // upscroll code
    window.scrollDirection = 'up';
  }

  lastScrollTop = st <= 0 ? 0 : st;
});

window.onresize = () => {
  new SplitType('[data-split-type]', {
    types: 'lines',
  });
};

const initSlider = () => {
  const sliders = document.querySelectorAll('[data-swiper]');

  window.swiper = [];

  Object.values(sliders).map(slider => {
    const timer = 6000;

    const sliderOptions = {
      modules: [Navigation, Pagination, Autoplay, Parallax, Keyboard],
      slidesPerView: parseFloat(slider.dataset.swiperSlidesPerView) || 'auto',
      parallax: true,
      centeredSlides: slider.dataset.swiperCentered === 'true',
      initialSlide: slider.dataset.swiperInitial || 0,
      loop: slider.dataset.swiperLoop === 'true',
      loopAdditionalSlides: slider.dataset.swiperLoop === 'true' ? 1 : null,
      speed: parseFloat(slider.dataset.swiperSpeed) || 1000,
      allowTouchMove: slider.dataset.swiperAllowMove !== 'false',
      direction: slider.dataset.swiperVertical === 'true' ? 'vertical' : 'horizontal',
      height: parseFloat(slider.dataset.swiperHeight) || null,
      autoplay: slider.dataset.swiperAutoplay !== 'false' ? {
        delay: parseFloat(slider.dataset.swiperTimer) || timer,
        pauseOnMouseEnter: true,
        reverseDirection: slider.dataset.swiperReverse === 'true'
      } : false,
      navigation: slider.dataset.navigation !== 'false' ? {
        prevEl: slider.dataset.swiperPrev || '.swiper-button-prev', nextEl: slider.dataset.swiperNext || '.swiper-button-next',
      } : false,
      pagination: slider.dataset.swiperPagination ? {
        el: slider.dataset.swiperPaginationElement || '.swiper-pagination',
      } : false,
      keyboard: {
        enabled: true,
      },
    };

    const swiper = new Swiper(slider, sliderOptions);
    window.swiper.push(swiper);

    //accessibility
    swiper.on('slideChange', e => {
      [...swiper.slides].map(item => {
        const link = item.querySelector('a');

        if (link) {
          link.setAttribute('tabindex', item.classList.contains('swiper-slide-visible') ? '0' : '-1');
          link.blur();
        }
      });

      if (isInViewport(swiper.el)) {
        setTimeout(() => {
          swiper.slides[e.activeIndex].querySelector('a')?.focus();
        }, 500);
      }
    });
  });
};

/****
 *    Function : buttonApplyHandle
 *    Usage : this function handles the scroll animation for the Apply button
 ****/
const buttonApplyHandle = () => {
  const button = document.querySelector('.button--apply');
  if (button) {
    const wrapper = document.querySelector('.offer--content');
    const offerTitle = document.querySelector('.offer--title');

    window.addEventListener('scroll', () => {
      //If the button enters the screen
      if (wrapper.getBoundingClientRect().bottom <= window.innerHeight) {
        //Unstick the button
        button.classList.remove('stuck');
      }
      else {
        //If the button wrapper leaves the screen, stick the button
        if (!isInViewport(offerTitle) && wrapper.getBoundingClientRect().bottom >= window.innerHeight) {
          button.classList.add('stuck');
        }
      }
    });
  }
};

const menuHandle = () => {
  const navWrapper = document.querySelector('.navigation--wrapper');

  const elements = document.querySelectorAll('[data-menu-hover]');

  const navItems = document.querySelectorAll('.navigation ul:not([data-menu-hover-element]) > li');

  const events = ['mouseenter'];

  [...navItems].map(item => {
    events.map(event => {
      item.addEventListener(event, () => {
        const active = document.querySelector('[data-menu-hover-element].active');
        const hovered = document.querySelector('li.hover');

        active?.classList.remove('active');
        hovered?.classList.remove('hover');
      });
    });
  });

  [...elements].map(item => {
    const id = item.dataset.menuHover;
    const toToggle = item.closest('.menu--children').querySelector(`[data-menu-hover-element="${id}"]`);

    events.map(event => {
      item.addEventListener(event, () => {
        const active = document.querySelector('[data-menu-hover-element].active');
        const hovered = document.querySelector('li.hover');
        active?.classList.remove('active');
        hovered?.classList.remove('hover');

        toToggle.classList.add('active');
        item.classList.add('hover');

        new SplitType(`.menu-publications--content .h1`, {
          types: 'lines',
        });
      });
    });
  });

  window.addEventListener('scroll', () => {
    if (navWrapper.getBoundingClientRect().top <= 0) {//&& window.scrollDirection === 'down'
      navWrapper.classList.add('stuck');
    }
    else {
      navWrapper.classList.remove('stuck');
    }
  });
};

const menuHandleMobile = () => {
  const thirdNav = document.querySelectorAll('.menu--thirdnav ul');
  [...thirdNav].map((nav, index) => {
    document.querySelectorAll('.menu--subnav > ul > .menu-item')[index].appendChild(nav);
  });

  const parentItems = document.querySelectorAll('.menu-item.menu-item-has-children > .menu-item--wrapper');
  [...parentItems].map(parent => {
    parent.addEventListener('click', () => {
      parent.closest('li').classList.toggle('show');
    });
  });

  const subNavClose = document.querySelectorAll('.menu--subnav > .button--back');

  [...subNavClose].map(item => {
    item.addEventListener('click', () => {
      item.closest('.show').classList.remove('show');
    });
  });
};

const searchFormHandle = () => {
  const buttonDelete = document.querySelectorAll('.button--delete');

  [...buttonDelete].map(button => {
    if (button) {
      const input = button.closest('form').querySelector('input');

      button.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();

        input.value = '';

        if (window.is_mobile) {
          if (input.closest('#filterKeyword')) {
            document.getElementById('filterForm').submit();
          }
        }

        input.dispatchEvent(new KeyboardEvent('keyup', {'key': 'Backspace'}));
      });
    }
  });

  const search = document.querySelector('.form--search');

  if (search && window.is_mobile) {
    const buttonClose = search.querySelector('.button--close');
    search.addEventListener('touchstart', () => search.classList.add('search-hover'));
    buttonClose.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();

      search.classList.remove('search-hover');
    });
  }
};

const portraitsHandle = () => {
  const summary = document.querySelector('.portrait--summary ul');

  if (summary) {
    const titles = document.querySelectorAll('.article-body h2');

    [...titles].map((item, index) => {
      item.id = `portrait-${index}`;
      const listItem = document.createElement('LI');
      listItem.innerText = item.innerText;
      listItem.dataset.scrollto = item.id;

      summary.appendChild(listItem);
      scrollTo();
    });
  }
};

const homeAnimation = () => {
  const solutionsImages = document.querySelectorAll('.home-solutions--inner-images img');
  const logoWrapper = document.querySelector('.home-solutions--logo .logo--wrapper');

  const tags_wrapper = document.querySelector('.home-solutions--buttons .tags--listing');
  const tags = tags_wrapper?.querySelectorAll('li');
  const button = document.querySelector('.home-solutions--buttons .button--action');

  const textToChange = document.querySelectorAll('[data-change-text]');

  [...textToChange].map(text => {
    const lines = text.querySelectorAll('.line');

    [...lines].map(line => line.style.height = line.getBoundingClientRect().height + 'px');
  });

  [...solutionsImages].map((image, index) => {
    const figure = image.closest('figure');

    window.addEventListener('scroll', () => {
      const rect = image.getBoundingClientRect();

      if (isInViewport(image, true)) {
        const scrollableDistance = window.innerHeight / 10 + rect.height;
        const scrolled = window.innerHeight - rect.top;

        const pct = (scrolled / scrollableDistance);

        gsap.set(image, {
          opacity: pct,
          y: (pct * 30) / index,
        });

        //translate X image animation
        if (index === 1 || index === 2) {
          let x, translateX;

          if (index === 1) {
            x = -200 + (200 * (pct / 2));
            translateX = x <= 0 ? x : 0;
          }

          if (index === 2) {
            x = 200 + (-200 * (pct / 2));
            translateX = x >= 0 ? x : 0;
          }

          gsap.to(figure, .2, {
            x: translateX,
          });
        }
      }

      if (rect.top <= 0 && rect.bottom >= 0) {

      }
    });
  });

  if (logoWrapper) {
    if (!is_mobile) {
      window.addEventListener('scroll', () => {
        const logoRect = logoWrapper.getBoundingClientRect();
        const parent = logoWrapper.closest('.section--home-solutions');
        const parentRect = parent.getBoundingClientRect();

        if (parentRect.top <= -(logoRect.height / 2)) {
          if (parent.classList.contains('changed')) {
            return;
          }

          changeText(textToChange);

          [...tags].map((tag, index) => {

            gsap.to(tag, .2, {
              opacity: 0,
              x: 10,
              onComplete: () => {
                if (index === tags.length - 1) {
                  gsap.set(tags_wrapper, {
                    order: 3,
                  });

                  tags_wrapper.dataset.height = tags_wrapper.getBoundingClientRect().height;

                  gsap.to(tags_wrapper, .2, {
                    height: 0,
                    display: 'none',
                  });

                  gsap.set(button, {
                    display: 'flex',
                    onComplete: () => {
                      gsap.to(button, .2, {
                        opacity: 1,
                      });
                    },
                  });
                }
              },
            });
          });

          parent.classList.add('changed');
        }
        else {
          if (!parent.classList.contains('changed')) {
            return;
          }

          changeText(textToChange);

          gsap.to(button, .2, {
            opacity: 0,

            onComplete: () => {
              gsap.to(tags_wrapper, .2, {
                height: tags_wrapper.dataset.height,
                display: 'flex',
                onComplete: () =>
                  gsap.set(tags_wrapper, {
                    order: 0,
                  }),
              });

              [...tags].map((tag, index) => {
                gsap.to(tag, .2, {
                  opacity: 1,
                  x: 0,
                  delay: .1 * index,
                });
              });
            },
          });

          parent.classList.remove('changed');
        }
      });
    }
    else {
      const logos = document.querySelectorAll('.home-solutions--logo');
      const sectors = document.querySelector('.home-solutions--images-sectors');

      sectors.innerHTML += logos[0].outerHTML + logos[1].outerHTML;

      [...logos].map(logo => logo.remove());
    }

  }
};

const changeText = (textToChange) => {
  [...textToChange].map((text, index) => {
    const new_text = text.dataset.text;
    const old_text = text.innerText;

    gsap.to(text, .2, {
      opacity: 0,
      y: 20,
      delay: .1 * index,
      onComplete: () => {
        text.innerHTML = new_text;
        text.setAttribute('data-text', old_text);

        gsap.to(text, .2, {
          opacity: 1,
          y: 0,
          delay: .1 * index,
        });
      },
    });
  });
};

const eventsAnimation = () => {
  const event_featured = document.querySelector('.events-featured');
  if (event_featured) {
    const events_items = document.querySelectorAll('.home-events--item');

    const img = event_featured.querySelector('img');
    const title = event_featured.querySelector('h3');
    const date = event_featured.querySelector('.event--date');
    const hour = event_featured.querySelector('time:last-child');
    const location = event_featured.querySelector('address span:first-child');
    const address = event_featured.querySelector('address span:last-child');

    [...events_items].map(item => {
      item.addEventListener('mouseenter', () => {
        document.querySelector('.home-events--item.hover')?.classList.remove('hover');
        item.classList.add('hover');
      });

      item.addEventListener('mouseover', () => {
        img.closest('.media-image').querySelector('source')?.remove();
        img.src = item.dataset.image;
        date.innerHTML = item.querySelector('.event--date').innerHTML;
        hour.innerHTML = item.querySelector('time:last-child').innerHTML;
        location.innerHTML = item.dataset.location;
        address.innerHTML = item.dataset.address;
        title.innerText = item.querySelector('h3').innerText;

        return;

        gsap.to('.events-featured--text', .2, {
          opacity: 0,
          //y: 20,
          onComplete: () => {

            gsap.to('.events-featured--text', .2, {
              opacity: 1,
              //y: 0,
            });
          },
        });
      });
    });
  }
};

const recruitmentProccesAnimation = () => {
  const listing = document.querySelector('.steps--listing');

  if (listing) {
    const items = listing.querySelectorAll('li');

    [...items].map((item, index) => {
      window.addEventListener('scroll', () => {
        const rect = item.getBoundingClientRect();

        if (rect.top <= ((window.innerHeight / 2) - (rect.height / 2))) {
          item.classList.add('active');
        }
        else {
          item.classList.remove('active');
        }
      });
    });
  }
};

const newsletterForm = () => {
  const form_large = document.getElementById('newsletterForm');
  const form_small = document.getElementById('newsletterFormSmall');

  const forms = [form_large, form_small];

  if (form_large || form_small) {
    forms.map(form => {
      form?.addEventListener('submit', e => {
        e.preventDefault();
        e.stopPropagation();

        const optin = document.getElementById('pardot_extra_field');

        const inputs = form.querySelectorAll('input,textarea,select');
        const contactData = new FormData(form);

        [...inputs].map(item => {
          contactData[item.getAttribute('name')] = item.value;
        });

        if (
          optin.value === ''
          && contactData['nl_email'] !== ''
          && !e.target.classList.contains('form--sending')
        ) {
          e.target.classList.add('form--sending');
          fetch('/wp-json/newsletter/v1/add', {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(contactData),
          }).then(resp => resp.json()).then(async resp => {

            if (resp.status === 200) {
              if (form.dataset.action) {
                window.location = form.dataset.action + '?status=sent';
              }
            }

            if (resp.status === 200) {
              const inputToFlush = form.querySelectorAll(
                'input:not(#pardot_extra_field, #lead_source, #retURL, #oid, #Form_Source__c, #utmsource__c, #fileDownload, #articleTitle),textarea,select');

              Object.values(inputToFlush).map(input => {
                input.value = '';
                input.classList.remove('edited');
              });

              e.target.classList.remove('form--sending');
              e.target.classList.add('form--sent');
            }
          });
        }
      });
    });
  }
};

const accessibility = () => {
  //share-tooltip
  const tooltips = document.querySelectorAll('.share--tooltip');
  [...tooltips].map(item => {
    const button = item.closest('button');
    button.addEventListener('focus', () => {
      if (!is_mobile) {
        button.classList.add('focus');
      }
    });

    const sub_links = item.querySelectorAll('a');
    [...sub_links].map((link, index) => {
      link.addEventListener('blur', () => {
        if (!is_mobile && index === sub_links.length - 1) {
          button.classList.remove('focus');
        }
      });
    });
  });

  //Home Button sectors
  const buttonSectors = document.querySelector('.home-solutions--buttons #button_sectors');

  if (buttonSectors) {
    buttonSectors.addEventListener('focus', () => {
      const sectorImages = document.querySelector('.home-solutions--images-sectors');
      sectorImages.scrollIntoView({behavior: 'smooth'});
    });
  }

  window.addEventListener('keydown', e => {
    //On tapping escape, we check if navigation is active
    if (document.activeElement && document.activeElement.closest('.menu-item')) {

      //We get the first link of main
      const nextItemToFocus = document.querySelector('main a');
      if (e.key === 'Escape') {

        //Blur navigation item and focus first link
        document.activeElement.blur();
        nextItemToFocus.focus();
      }
    }
  });
};
