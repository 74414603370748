export default function scrollTo() {
  const scrollToButton = document.querySelectorAll('[data-scrollto]');

  Object.values(scrollToButton).map(button =>
    button.addEventListener('click', () => {
      const element = document.getElementById(button.dataset.scrollto);

      //element.scrollIntoView({behavior: "smooth", block: 'start'})

      window.scrollTo({
        behavior: 'smooth',
        top:
          element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 100,
      })
    })
  )
}
