export default function createShareButton() {
  const btn = document.getElementById('share');
  if (btn) {
    btn.querySelector('span').innerText = 'share' in navigator ? 'Partager' : 'Envoyer';

    const title = document.querySelector('title').innerText;
    const text = document.querySelector('.offer--content-text').innerText;
    const url = window.location.href;

    btn.onclick = () => {
      if (navigator.share !== undefined) {
        navigator.share({
          title,
          text,
          url,
        }).then(() => console.log('Shared!')).catch(err => console.error(err));
      }
      else {
        window.location = `mailto:?subject=${title}&body=${text}%0A${url}`;
      }
    };
  }
}
