window.onload = () => {
  copyUrlFn();
  showSocial();
}

const copyUrlFn = () => {
  const copyArticleLink = document.getElementById('copy_article_link');
  if (copyArticleLink != null) {
    copyArticleLink.addEventListener('click', function(event) {
      event.preventDefault();
      var currentURL = window.location.href;

      var tempInput = document.createElement('input');
      tempInput.value = currentURL;
      document.body.appendChild(tempInput);

      tempInput.select();
      tempInput.setSelectionRange(0, 99999);
      document.execCommand('copy');
      document.body.removeChild(tempInput);

      alert('URL copié dans le presse-papier : ' + currentURL);
    });
  }
};

const showSocial = () => {
  const social = document.querySelector('.share--social');
  const button = document.querySelector('#display_social_links')?.closest('li');

  button?.addEventListener('mouseenter', () => {
    social.classList.add('active');
  });

  button?.addEventListener('mouseleave', () => {
    social.classList.remove('active');
  });
};
