import gsap from 'gsap';

/****
 *    Function : accordionFn
 *    Usage : Initialize accordion with height animation
 ****/
const accordionFn = () => {
  const accordions = document.querySelectorAll('[data-accordion]');
  //Resize event
  window.addEventListener('resize', () => {
    Object.values(accordions).map(element => {
      const content = element.querySelector('[data-accordion-content]');

      //We reset the size of the element to update and re-store the height of the element
      gsap.set(content, {
        position: 'absolute',
        height: 'auto',
        onComplete: () => {
          //Restoring the height
          content.setAttribute('data-height', content.offsetHeight);

          //Closing the accordion content
          gsap.set(content, {
            position: 'relative',
            height: 0,
          });
        },
      });
    });
  });

  Object.values(accordions).map(element => {

    //[data-accordion], [data-accordion-title] and [data-accordion-content] are the three required data attributes to make the accordion work.
    /*
    <div data-accordion>
      <h1 data-accordion-title>The title of the section</h1>

      <div data-accordion-content>The content of the accordion</div>
    </div>
     */

    const title = element.querySelector('[data-accordion-title]');
    const content = element.querySelector('[data-accordion-content]');
    const is_unique = element.dataset.accordionUnique;
    if (element.classList.contains('default-open')) {
      setTimeout(() => {
        title.click();
        element.classList.remove('default-open');
      }, 10);
    }

    //We store the initial height of the element
    content.setAttribute('data-height', content.offsetHeight);

    //Closing the accordion content
    gsap.set(content, {
      position: 'relative',
      height: 0,
    });

    //Click event on the title
    title.addEventListener('click', () => {
      if (is_unique && !element.classList.contains('open')) {
        const active = document.querySelector(`[data-accordion-unique="${is_unique}"].open`);
        if (active) {
          const activeTitle = active.querySelector('[data-accordion-title]');
          activeTitle.click();
        }
      }

      //Toggle the "open" class to handle the button animation "+" to "-"
      element.classList.toggle('open');

      if (element.classList.contains('open')) {
        //When the state of the accordion is set to "open" we set the height of the content with the value we initially stored in the data attribute.
        gsap.to(content, .4, {
          height: 'auto', opacity: 1,
        });
      }
      else {
        //When the state of the accordion is closed we remove the height of the content
        gsap.to(content, .4, {
          height: 0, opacity: 0,
        });
      }
    });

    title.addEventListener('focus-visible', () => {
      if (!element.classList.contains('open')) {
        //title.click();
      }
    });
  });
};

export default accordionFn;
